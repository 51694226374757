<template>
  <v-container id="workouts" fluid tag="section" class="container-full-size">
    <v-data-table
      :headers="headers"
      :items="workoutsFiltered"
      :items-per-page="tableItemsPerPage"
      :page="currentPage"
      :search="search"
      :no-data-text="str['exercises_empty']"
      :no-results-text="str['exercises_empty']"
      class="elevation-1"
      :custom-filter="customTableSearch"
      @click:row="editWorkout"
      @update:page="(value) => (currentPage = value)"
      @update:items-per-page="(value) => (tableItemsPerPage = value)"
    >
      <template #top>
        <v-toolbar v-if="showTypeTabs" flat class="toolbar-adjust table-toolbar-no-padding-sides pb-0">
          <v-row>
            <v-col cols="12" class="pb-1">
              <v-tabs
                v-model="typeTab"
                background-color="transparent"
                color="secondary"
                style="margin-top: 0"
                grow
                show-arrows
                @change="changeTypeTab"
              >
                <v-tab v-for="tab in typesItems" :key="tab.value">
                  {{ tab.label }}
                </v-tab>
              </v-tabs>
            </v-col>
          </v-row>
        </v-toolbar>
        <v-toolbar flat>
          <v-text-field v-model="search" append-icon="mdi-magnify" :label="str['search']" single-line hide-details />
          <v-spacer />
          <v-dialog v-model="dialogWorkout" persistent max-width="750px">
            <template #activator="{ on, attrs }">
              <v-btn v-if="editPermission" color="success" dark class="mb-2" v-bind="attrs" v-on="on" @click="openNewWorkout()">
                {{ str['add'] }}
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">
                  {{ editedIndex === -1 ? str['new_exercise'] : str['edit_exercise'] }}
                </span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col v-for="item in workoutData" :key="item.id" cols="12">
                      <div v-if="item.parentTitle" style="margin-bottom: 5px; font-weight: bold">
                        {{ item.parentTitle }}
                      </div>
                      <v-text-field
                        v-if="item.type === 'input' && item.id !== 'video_url' && item.id !== 'video_url2'"
                        v-model="item.value"
                        :label="item.title"
                        @blur="item.value = item.value ? normalizeName(item.value) : item.value"
                      />
                      <v-textarea v-if="item.type === 'textarea'" v-model="item.value" :label="item.title" hide-details />
                      <v-autocomplete
                        v-if="item.type === 'select'"
                        v-model="item.value"
                        :label="item.title"
                        item-text="label"
                        item-value="value"
                        :items="item.items"
                        :multiple="item.multiple"
                        :no-data-text="str['no_data']"
                      />
                      <div
                        v-if="item.id === 'video_url' || item.id === 'video_url2'"
                        style="display: flex; position: relative"
                        :class="{
                          'custom-disabled': !dialogWorkoutName || (dialogWorkoutName && !dialogWorkoutName.value),
                        }"
                      >
                        <v-text-field v-model="item.value" :label="item.title" />
                        <div v-if="uploadVideoImagekit || uploadVideoServer" class="ml-2">
                          <input id="input-file-video" type="file" accept=".mov,.mp4" @change="addNewVideo($event, item)" />
                          <v-btn class="mx-2" fab dark small color="success">
                            <v-icon dark>mdi-plus</v-icon>
                          </v-btn>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" style="position: relative">
                      <v-carousel v-model="currentPhoto">
                        <v-carousel-item
                          v-for="(workoutPhoto, i) in workoutPhotos"
                          :key="i"
                          :src="workoutPhoto.photo"
                          contain
                          reverse-transition="fade-transition"
                          transition="fade-transition"
                        />
                      </v-carousel>
                      <div class="title button-actions">
                        <v-btn class="mx-2" fab dark small color="red" @click="deletePhoto">
                          <v-icon dark>mdi-delete</v-icon>
                        </v-btn>
                        <v-btn v-if="currentPhoto > 0" class="mx-2" fab dark small color="default" @click="pinPhoto">
                          <v-icon dark>mdi-pin</v-icon>
                        </v-btn>
                        <v-btn v-if="currentPhoto === 0" class="mx-2" fab dark small color="primary" style="pointer-events: none">
                          <v-icon dark>mdi-pin</v-icon>
                        </v-btn>
                        <input id="input-file-image" type="file" accept=".png,.jpg,.jpeg" @change="addNewPhoto" />
                        <v-btn class="mx-2" fab dark small color="success">
                          <v-icon dark>mdi-plus</v-icon>
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn color="default" @click="cancelWorkout">
                  {{ str['cancel'] }}
                </v-btn>
                <v-btn color="success" @click="saveWorkout">
                  {{ str['save'] }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
        <v-toolbar v-if="hasMuscleGroupFilter || hasPlace || hasTypeFilter" flat class="toolbar-adjust">
          <v-row>
            <v-col v-if="hasMuscleGroupFilter" cols="12" :lg="4" :md="4" :sm="12">
              <v-autocomplete
                v-model="filters.muscleGroup"
                :label="str[filtersNames && filtersNames.muscle_group ? filtersNames.muscle_group : 'muscle_group']"
                item-text="label"
                item-value="value"
                :items="muscleGroupsItems"
                :no-data-text="str['no_data']"
                clearable
                hide-details
                multiple
                style="max-width: 300px"
              />
            </v-col>
            <v-col v-if="hasPlace" :cols="12" :lg="4" :md="4" :sm="12">
              <v-autocomplete
                v-model="filters.place"
                :label="str['place']"
                item-text="label"
                item-value="value"
                :items="workoutPlacesItems"
                :no-data-text="str['no_data']"
                clearable
                hide-details
                style="max-width: 300px"
              />
            </v-col>
            <v-col v-if="hasTypeFilter" :cols="12" :lg="4" :md="4" :sm="12">
              <v-autocomplete
                v-model="filters.type"
                :label="str[filtersNames && filtersNames.type ? filtersNames.type : 'type']"
                item-text="label"
                item-value="value"
                :items="typesItems"
                :no-data-text="str['no_data']"
                clearable
                hide-details
                style="max-width: 300px"
              />
            </v-col>
            <v-col v-if="showDeletedFilter" :cols="12" :lg="4" :md="4" :sm="12">
              <v-checkbox
                v-model="showWorkoutsDeleted"
                :label="str['show_deleted']"
                class="form-field-checkbox"
                style="margin: 0"
                hide-details
              />
            </v-col>
          </v-row>
        </v-toolbar>
      </template>
      <template #item.photo="{ item }">
        <div class="p-2 text-center" style="display: flex; justify-content: center; padding: 10px 0">
          <v-img contain :src="item.photo" :alt="item.name" width="60px" height="60px" />
        </div>
      </template>
      <template #item.muscle_group="{ item }">
        <span v-if="config.workouts && config.workouts.multiple_muscle_grups">
          {{ muscleGroupKeys[item.muscle_group] }} | {{ muscleGroupKeys[item.muscle_group_2] }} |
          {{ muscleGroupKeys[item.muscle_group_3] }} |
          {{ muscleGroupKeys[item.muscle_group_4] }}
        </span>
        <span v-else>
          {{ muscleGroupKeys[item.muscle_group] }}
        </span>
      </template>
      <template #item.video_url="{ item }">
        <div class="text-center" style="width: 300px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; margin: 0 auto">
          <a v-if="item.video_url.indexOf('mkgest.com') === -1" target="_blank" :href="item.video_url" @click.stop>
            {{ item.video_url }}
          </a>
          <a
            v-if="item.video_url.indexOf('mkgest.com') > -1"
            style="text-decoration: underline"
            class="cursor-hover"
            @click.stop="openVideo(item)"
          >
            {{ item.video_url }}
          </a>
        </div>
        <div
          v-if="hasMultipleVideos && item.video_url2"
          class="text-center"
          style="width: 300px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; margin: 0 auto"
        >
          <a target="_blank" :href="item.video_url2" @click.stop>
            {{ item.video_url2 }}
          </a>
        </div>
      </template>
      <template #item.actions="{ item }">
        <v-icon v-if="item.status !== statusDeleted" medium color="error" class="mr-0" @click.stop="deleteWorkout(item)">mdi-delete</v-icon>
      </template>
      <template #footer.page-text="{ pageStart, pageStop, itemsLength }">
        {{ pageStart }}-{{ pageStop }} {{ str['of'] }} {{ itemsLength }}
      </template>
    </v-data-table>

    <v-dialog v-if="videoOpened" v-model="dialogVideo" persistent max-width="1000px">
      <v-card>
        <v-card-title>
          <div>
            <span class="headline">
              {{ videoOpened.name }}
            </span>
          </div>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col :cols="12" class="text-center">
              <video :src="videoOpened.video_url" controls style="max-height: 500px"></video>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="default" @click="closeVideo">
            {{ str['close'] }}
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Api from '@/services/Api'
import StorageApi from '@/services/StorageApi'
import Utils from '@/services/Utils'
import axios from 'axios'

export default {
  data() {
    const user = Utils.getUser()
    const editPermission = Utils.hasPermission('workouts_edit')

    const hidePhotos =
      user && user.configurations && user.configurations.workouts && user.configurations.workouts.hide_photos ? true : false

    const hasMultipleVideos =
      user && user.configurations && user.configurations.workouts && user.configurations.workouts.multiple_videos ? true : false

    const uploadVideoImagekit = user && user.configurations && user.configurations.imagekit ? user.configurations.imagekit : false

    const uploadVideoServer =
      user && user.configurations && user.configurations.workouts && user.configurations.workouts.upload_video
        ? user.configurations.workouts.upload_video
        : false

    const hasPlace = user && user.configurations && user.configurations.workouts && user.configurations.workouts.has_place ? true : false

    const showTypeTabs =
      user && user.configurations && user.configurations.workouts && user.configurations.workouts.show_type_tabs ? true : false

    const hasAlternatives =
      user && user.configurations && user.configurations.workouts && user.configurations.workouts.has_alternatives ? true : false

    const hasFlags = user && user.configurations && user.configurations.workouts && user.configurations.workouts.has_flags ? true : false

    const hasMultipleMuscleGroups =
      user && user.configurations && user.configurations.workouts && user.configurations.workouts.multiple_muscle_grups ? true : false

    const hasTarget = user && user.configurations && user.configurations.workouts && user.configurations.workouts.has_target ? true : false

    const hasMuscleGroupFilter =
      user && user.configurations && user.configurations.workouts && user.configurations.workouts.has_muscle_group_filter ? true : false

    const hasTypeFilter =
      user && user.configurations && user.configurations.workouts && user.configurations.workouts.has_type_filter ? true : false

    const hasTranslations =
      user && user.configurations && user.configurations.workouts && user.configurations.workouts.has_translations
        ? user.configurations.workouts.has_translations
        : false

    const filtersNames =
      user && user.configurations && user.configurations.workouts && user.configurations.workouts.filters_names
        ? user.configurations.workouts.filters_names
        : false

    let showDeletedFilter = true
    if (user && user.configurations && user.configurations.workouts && user.configurations.workouts.hide_deleted) {
      showDeletedFilter = false
    }

    const headers = []

    if (!hidePhotos) {
      headers.push({
        text: window.strings['photography'],
        align: 'center',
        value: 'photo',
      })
    }

    headers.push({
      text: window.strings['name'],
      value: 'name',
      align: 'center',
      width: 300,
    })
    headers.push({
      text: window.strings[filtersNames && filtersNames.muscle_group ? filtersNames.muscle_group : 'muscle_group'],
      value: 'muscle_group',
      align: 'center',
    })
    headers.push({
      text: window.strings['video'],
      value: 'video_url',
      align: 'center',
      width: 200,
    })

    if (editPermission) {
      headers.push({
        text: window.strings['actions'],
        value: 'actions',
        sortable: false,
        align: 'center',
      })
    }

    // FRANCISCO ESPIN HARDCODED - EXERCISES VIDEOS
    if (
      user &&
      user.configurations &&
      user.configurations.workouts &&
      user.configurations.workouts.upload_video &&
      user.configurations.workouts.upload_video.api_url
    ) {
      axios.defaults.storageUrl = user.configurations.workouts.upload_video.api_url
    }

    return {
      str: window.strings,
      user: user,
      config: user && user.configurations ? user.configurations : null,
      editPermission: editPermission,
      hasMultipleVideos: hasMultipleVideos,
      uploadVideoImagekit: uploadVideoImagekit,
      uploadVideoServer: uploadVideoServer,
      hasPlace: hasPlace,
      showTypeTabs: showTypeTabs,
      hasAlternatives: hasAlternatives,
      hasFlags: hasFlags,
      hasMultipleMuscleGroups: hasMultipleMuscleGroups,
      hasTarget: hasTarget,
      hasMuscleGroupFilter: hasMuscleGroupFilter,
      hasTypeFilter: hasTypeFilter,
      hasTranslations: hasTranslations,
      filtersNames: filtersNames,
      showDeletedFilter: showDeletedFilter,
      showPhotos: !hidePhotos,
      muscleGroupKeys: this.getMuscleGroupsKeys(),
      muscleGroupsItems: Utils.getMuscleGroups(),
      workoutPlacesItems: hasPlace ? Utils.getWorkoutPlaces() : [],
      typesItems: Utils.getWorkoutTypes(),
      headers: headers,
      currentPage: 1,
      tableItemsPerPage: Utils.getTableCache('workouts', 'itemsPerPage') || 10,
      imageMaxSize: 300,
      currentPhoto: 0,
      workoutPhotos: [],
      workouts: [],
      dialogWorkout: false,
      editedIndex: -1,
      workoutData: [],
      search: '',
      filters: Utils.getTableCache('workouts', 'filters') || {
        muscleGroup: null,
        place: null,
        type: null,
      },
      typeTab: 0,
      typePhotosDone: {},
      dialogVideo: false,
      videoOpened: null,
      showWorkoutsDeleted: false,
      statusDeleted: 1,
      normalizeName: Utils.normalizeName,
    }
  },
  computed: {
    workoutsFiltered() {
      const self = this
      let workouts = this.workouts
      if (this.showTypeTabs) {
        workouts = workouts.filter(function (item) {
          return item.type === self.typesItems[self.typeTab].value
        })
      }
      if (this.hasMuscleGroupFilter && this.filters.muscleGroup && this.filters.muscleGroup.length) {
        workouts = workouts.filter(function (item) {
          return self.filters.muscleGroup.indexOf(item.muscle_group) > -1
        })
      }
      if (this.hasPlace && (this.filters.place || this.filters.place === 0)) {
        workouts = workouts.filter(function (item) {
          return item.place === self.filters.place
        })
      }
      if (this.hasTypeFilter && (this.filters.type || this.filters.type === 0)) {
        workouts = workouts.filter(function (item) {
          return item.type === self.filters.type
        })
      }
      if (this.showDeletedFilter) {
        workouts = workouts.filter(function (item) {
          return self.showWorkoutsDeleted ? item.status === self.statusDeleted : item.status !== self.statusDeleted
        })
      }
      return workouts
    },
    dialogWorkoutName() {
      const field = this.workoutData.find(function (it) {
        return it.id === 'name'
      })
      return field
    },
  },
  watch: {
    dialogWorkout(val) {
      const dialog = document.getElementsByClassName('v-dialog')
      if (dialog && dialog[0]) {
        dialog[0].scrollTo(0, 0)
      }
      val || this.closeWorkout()
    },
  },
  beforeMount: function () {
    this.main()
  },
  beforeDestroy: function () {
    Utils.setTableCache('workouts', 'itemsPerPage', this.tableItemsPerPage)
    Utils.setTableCache('workouts', 'filters', JSON.parse(JSON.stringify(this.filters)))
    Utils.setTableCache('workouts', 'page', this.currentPage)
  },
  methods: {
    customTableSearch: function (value, search, item) {
      const normalize = (str) =>
        str
          ? str
              .toLowerCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
          : ''
      const normalizedSearch = normalize(search)
      if (normalize(item.name).includes(normalizedSearch)) {
        return true
      }
      const muscleGroups = ['muscle_group', 'muscle_group_2', 'muscle_group_3', 'muscle_group_4']
      for (const key of muscleGroups) {
        if (this.hasMultipleMuscleGroups || key === 'muscle_group') {
          const muscleGroup = normalize(this.muscleGroupKeys[item[key]])
          if (muscleGroup.includes(normalizedSearch)) {
            return true
          }
        }
      }
      return false
    },
    main: function () {
      if (!this.user) {
        return false
      }
      this.$isLoading(true)
      this.setWorkouts()
      this.setWorkoutDataFields()
      if (!window.useResetCacheSockets) {
        Utils.removeRequestCache('workouts')
      }
    },
    setWorkouts: function () {
      const self = this
      const fields = ['id', 'name', 'muscle_group', 'video_url', 'type', 'place']
      if (this.showDeletedFilter) {
        fields.push('status')
      }
      Api.getWorkouts(
        {
          fields: fields,
        },
        function (response) {
          self.$isLoading(false)
          if (response.success) {
            self.workouts = response.data
            self.currentPage = Utils.getTableCache('workouts', 'page') || self.currentPage
            self.setWorkoutsPhotos()
          } else {
            self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
          }
        },
      )
    },
    changeTypeTab: function () {
      this.setWorkoutsPhotos()
    },
    setWorkoutsPhotos: function (idsToRefresh) {
      const self = this
      if (!this.showPhotos) {
        return false
      }
      let workoutIds = []
      if (idsToRefresh) {
        idsToRefresh.forEach(function (idRef) {
          delete window.cachePhotos['workout-' + idRef]
        })
        workoutIds = idsToRefresh
      } else {
        if (this.showTypeTabs) {
          if (this.typePhotosDone[this.typeTab]) {
            return true
          }
          workoutIds = this.workouts
            .filter(function (item) {
              return item.type === self.typesItems[self.typeTab].value
            })
            .map(function (item) {
              return item.id
            })
          this.typePhotosDone[this.typeTab] = true
        } else {
          workoutIds = this.workouts.map(function (item) {
            return item.id
          })
        }
        for (let i = 0; i < this.workouts.length; i++) {
          const index = workoutIds.indexOf(this.workouts[i].id)
          if (index > -1) {
            if (window.cachePhotos['workout-' + this.workouts[i].id]) {
              this.workouts[i].photo = window.cachePhotos['workout-' + this.workouts[i].id]
              workoutIds.splice(index, 1)
            } else {
              this.workouts[i].photo = require('@/assets/loading.gif')
            }
            this.$set(this.workouts, i, this.workouts[i])
          }
        }
      }
      const splitIds = Utils.splitArray(workoutIds, 5)
      getPhotos(0)

      function getPhotos(index) {
        if (splitIds[index] && self.$route.path === '/home/workouts') {
          Api.getWorkoutPhotos(splitIds[index], function (response) {
            setPhotos(response, splitIds[index])
            getPhotos(index + 1)
          })
        }
      }

      function setPhotos(response, ids) {
        const photos = {}
        if (response.success) {
          for (let i = 0; i < response.data.length; i++) {
            photos[response.data[i].workout_id] = response.data[i].photo
          }
        }
        for (let i = 0; i < self.workouts.length; i++) {
          const id = self.workouts[i].id
          if (ids.indexOf(id) > -1) {
            const newPhoto = photos[id] ? photos[id] : require('@/assets/workout.png')
            window.cachePhotos['workout-' + id] = newPhoto
            self.workouts[i].photo = newPhoto
            self.$set(self.workouts, i, self.workouts[i])
          }
        }
      }
    },
    setWorkoutDataFields: function () {
      const self = this
      if (this.hasMultipleMuscleGroups) {
        Api.getSettings(
          {
            id: axios.defaults.ptId,
            useCache: true,
          },
          function (response) {
            if (response.success) {
              const settings = {}
              response.data.forEach(function (item) {
                settings[item.name] = item.value
              })
              setFields(settings)
            } else {
              self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
            }
          },
        )
      } else {
        setFields()
      }

      function setFields(settings) {
        const fields = []
        fields.push({
          type: 'none', // none = Not visible
          id: 'id',
          title: window.strings['id'],
          value: 0,
        })
        fields.push({
          type: 'input',
          id: 'name',
          title: window.strings['name'],
          value: '',
        })
        if (self.hasTranslations) {
          self.hasTranslations.forEach(function (tr) {
            fields.push({
              type: 'input',
              id: 'name_' + tr,
              title: window.strings['name'] + ' (' + tr.toUpperCase() + ')',
              value: '',
            })
          })
        }
        if (self.hasPlace) {
          fields.push({
            type: 'select',
            id: 'place',
            title: window.strings['place'],
            items: self.workoutPlacesItems,
            value: '',
          })
        }
        if (self.typesItems && self.typesItems.length) {
          fields.push({
            type: 'select',
            id: 'type',
            title: window.strings[self.filtersNames && self.filtersNames.type ? self.filtersNames.type : 'type'],
            items: self.typesItems,
            value: '',
          })
        }
        if (self.muscleGroupsItems && self.muscleGroupsItems.length) {
          fields.push({
            type: 'select',
            id: 'muscle_group',
            parentTitle: settings ? window.strings['muscle_group'] : null,
            title: settings
              ? settings['muscle_gr_1_name']
              : window.strings[self.filtersNames && self.filtersNames.muscle_group ? self.filtersNames.muscle_group : 'muscle_group'],
            items: self.muscleGroupsItems,
            value: '',
          })
        }
        if (self.hasMultipleMuscleGroups) {
          fields.push({
            type: 'select',
            id: 'muscle_group_2',
            title: settings['muscle_gr_2_name'],
            items: self.muscleGroupsItems,
            value: '',
          })
          fields.push({
            type: 'select',
            id: 'muscle_group_3',
            title: settings['muscle_gr_3_name'],
            items: self.muscleGroupsItems,
            value: '',
          })
          fields.push({
            type: 'select',
            id: 'muscle_group_4',
            title: settings['muscle_gr_4_name'],
            items: self.muscleGroupsItems,
            value: '',
          })
        }
        const levelsItems = Utils.getWorkoutLevels()
        if (levelsItems && levelsItems.length) {
          fields.push({
            type: 'select',
            id: 'level',
            title: window.strings['level'],
            items: levelsItems,
            value: '',
          })
        }
        const equipmentsItems = Utils.getWorkoutEquipments()
        if (equipmentsItems && equipmentsItems.length) {
          fields.push({
            type: 'select',
            id: 'equipment',
            title: window.strings['equipment'],
            items: equipmentsItems,
            multiple: true,
            isString: true,
            value: '',
          })
        }
        if (self.hasTarget) {
          fields.push({
            type: 'select',
            id: 'target',
            title: window.strings['goal'],
            items: Utils.getWorkoutTargets(),
            value: '',
          })
        }
        if (self.hasAlternatives) {
          fields.push({
            type: 'select',
            id: 'alternatives',
            title: window.strings['alternatives'],
            items: [],
            multiple: true,
            isString: true,
            value: '',
          })
        }
        if (self.hasFlags) {
          fields.push({
            type: 'select',
            id: 'flags',
            title: window.strings['exclude_options'],
            items: Utils.getWorkoutFlags(),
            multiple: true,
            value: '',
          })
        }
        fields.push({
          type: 'textarea',
          id: 'description',
          title: window.strings['description'],
          value: '',
        })
        if (self.hasTranslations) {
          self.hasTranslations.forEach(function (tr) {
            fields.push({
              type: 'textarea',
              id: 'description_' + tr,
              title: window.strings['description'] + ' (' + tr.toUpperCase() + ')',
              value: '',
            })
          })
        }
        fields.push({
          type: 'input',
          id: 'video_url',
          title: window.strings['video'],
          value: '',
        })
        if (self.hasMultipleVideos) {
          fields.push({
            type: 'input',
            id: 'video_url2',
            title: window.strings['video'],
            value: '',
          })
        }
        self.workoutData = fields
      }
    },
    getMuscleGroupsKeys: function () {
      const muscleGroups = Utils.getMuscleGroups()
      const keys = {}
      if (muscleGroups) {
        for (let i = 0; i < muscleGroups.length; i++) {
          keys[muscleGroups[i].value] = muscleGroups[i].label
        }
      }
      return keys
    },
    cancelWorkout() {
      this.closeWorkout()
    },
    openNewWorkout() {
      for (let i = 0; i < this.workoutData.length; i++) {
        this.workoutData[i].value = ''
        if (this.showTypeTabs && this.workoutData[i].id === 'type') {
          this.workoutData[i].value = this.typesItems[this.typeTab].value
        }
        if (this.hasAlternatives && this.workoutData[i].id === 'alternatives') {
          this.workoutData[i].items = this.workouts.map((a) => ({
            value: a.id,
            label: a.name,
          }))
        }
      }
      this.workoutPhotos = []
      this.currentPhoto = 0
      this.editedIndex = -1
      this.dialogWorkout = true
    },
    getWorkoutData: function () {
      const data = {}
      for (let i = 0; i < this.workoutData.length; i++) {
        if (this.workoutData[i].isString) {
          data[this.workoutData[i].id] = JSON.stringify(this.workoutData[i].value)
        } else {
          if (this.workoutData[i].id === 'flags') {
            data[this.workoutData[i].id] = this.encodeFlags({
              value: this.workoutData[i].value,
              items: this.workoutData[i].items,
              convertToFlags: true,
            })
          } else {
            data[this.workoutData[i].id] = this.workoutData[i].value
          }
        }
      }
      return data
    },
    setWorkoutData: function (data) {
      if (data) {
        for (let i = 0; i < this.workoutData.length; i++) {
          if (data[this.workoutData[i].id] !== undefined) {
            if (this.workoutData[i].isString && data[this.workoutData[i].id]) {
              this.workoutData[i].value = JSON.parse(data[this.workoutData[i].id])
            } else {
              if (this.workoutData[i].id === 'flags') {
                this.workoutData[i].value = this.encodeFlags({
                  value: data[this.workoutData[i].id],
                  items: this.workoutData[i].items,
                })
              } else {
                this.workoutData[i].value = data[this.workoutData[i].id]
              }
            }
          }
          if (this.hasAlternatives && this.workoutData[i].id === 'alternatives') {
            this.workoutData[i].items = this.workouts.map((a) => ({
              value: a.id,
              label: a.name,
            }))
          }
        }
        this.workoutPhotos = []
        for (let i = 0; i < data.photos.length; i++) {
          this.workoutPhotos.push(data.photos[i])
        }
        this.currentPhoto = 0
      }
    },
    encodeFlags: function (args) {
      const value = args.value
      const items = args.items
      const convertToFlags = args.convertToFlags
      let out = null
      if (convertToFlags) {
        out = 0
        value.forEach(function (val) {
          out |= val
        })
      } else {
        out = []
        if (value) {
          items.forEach(function (item) {
            if (value & item.value) {
              out.push(item.value)
            }
          })
        }
      }
      return out
    },
    editWorkout: function (workout) {
      if (this.editPermission) {
        const self = this
        this.editedIndex = this.workouts.indexOf(workout)
        this.$isLoading(true)
        Api.getWorkout(
          {
            id: workout.id,
            photos: 4,
          },
          function (response) {
            self.$isLoading(false)
            if (response.success) {
              self.setWorkoutData(response.data[0])
              self.dialogWorkout = true
            } else {
              self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
            }
          },
        )
      }
    },
    deleteWorkout(item) {
      const self = this
      const id = item.id
      this.$confirm(
        window.strings['want_delete_exercise'] + (item ? ' "' + item.name + '"' : '') + '?',
        '',
        'warning',
        Utils.getAlertOptions(true, true),
      )
        .then(() => {
          self.$isLoading(true)
          Api.deleteWorkout(id, function (response) {
            self.$isLoading(false)
            if (response.success) {
              for (let i = 0; i < self.workouts.length; i++) {
                if (self.workouts[i].id === id) {
                  self.workouts.splice(i, 1)
                  break
                }
              }
            } else {
              self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
            }
          })
        })
        .catch(() => {})
    },
    closeWorkout() {
      this.dialogWorkout = false
      this.$nextTick(() => {
        this.editedIndex = -1
        this.currentPhoto = 0
      })
    },
    saveWorkoutPhotos: function (workoutId) {
      const self = this
      for (let i = 0; i < this.workoutPhotos.length; i++) {
        this.workoutPhotos[i].workout_id = workoutId
      }
      Api.updateWorkoutPhotos(
        {
          id: workoutId,
          photos: this.workoutPhotos,
        },
        function (response) {
          if (response.success) {
            self.setWorkoutsPhotos([workoutId])
          } else {
            self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
          }
        },
      )
    },
    saveWorkout() {
      const self = this
      const data = this.getWorkoutData()
      this.$isLoading(true)
      if (this.editedIndex < 0) {
        Api.addWorkout(data, function (response) {
          self.$isLoading(false)
          if (response.success) {
            self.refreshWorkouts(response.data, true)
            self.closeWorkout()
          } else {
            self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
          }
        })
      } else {
        Api.updateWorkout(data, function (response) {
          self.$isLoading(false)
          if (response.success) {
            self.refreshWorkouts(response.data, false)
            self.closeWorkout()
          } else {
            self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
          }
        })
      }
    },
    refreshWorkouts(data, newWorkout) {
      const workout = {
        photo: require('@/assets/loading.gif'),
        id: data.id,
        name: data.name,
        type: data.type,
        muscle_group: data.muscle_group,
        muscle_group_2: data.muscle_group_2,
        muscle_group_3: data.muscle_group_3,
        muscle_group_4: data.muscle_group_4,
        target: data.target,
        level: data.level,
        description: data.description,
        video_url: data.video_url,
        video_url2: data.video_url2,
      }
      if (newWorkout) {
        this.workouts.push(workout)
      } else {
        this.workouts[this.editedIndex] = workout
      }
      const clone = JSON.parse(JSON.stringify(this.workouts))
      this.workouts = clone
      this.saveWorkoutPhotos(data.id)
      Utils.removeRequestCache('workouts')
    },
    pinPhoto() {
      const photos = []
      for (let i = 0; i < this.workoutPhotos.length; i++) {
        if (this.currentPhoto === i) {
          photos.unshift(this.workoutPhotos[i])
        } else {
          photos.push(this.workoutPhotos[i])
        }
      }
      this.currentPhoto = 0
      this.workoutPhotos = photos
    },
    deletePhoto() {
      this.workoutPhotos.splice(this.currentPhoto, 1)
    },
    addNewPhoto(event) {
      const self = this
      const file = event.srcElement.files[0]
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = function (e) {
        const image = new Image()
        image.src = reader.result
        image.onload = function () {
          if (image.width < self.imageMaxSize && image.height < self.imageMaxSize) {
            self.saveNewPhoto(e.target.result)
          } else {
            self.saveNewPhoto(resizeImage(image))
          }
        }
      }
      reader.onerror = function () {}

      function resizeImage(image) {
        const canvas = document.createElement('canvas')
        let width = image.width
        let height = image.height

        if (width > height) {
          if (width > self.imageMaxSize) {
            height *= self.imageMaxSize / width
            width = self.imageMaxSize
          }
        } else {
          if (height > self.imageMaxSize) {
            width *= self.imageMaxSize / height
            height = self.imageMaxSize
          }
        }
        canvas.width = width
        canvas.height = height
        const ctx = canvas.getContext('2d')
        ctx.drawImage(image, 0, 0, width, height)
        return canvas.toDataURL()
      }
    },
    saveNewPhoto(photo) {
      const workoutId = this.workoutData[0].value // Id values is index 0
      this.workoutPhotos.push({
        workout_id: workoutId,
        photo: photo,
      })
      this.currentPhoto = this.workoutPhotos.length - 1
      document.getElementById('input-file-image').value = ''
    },
    addNewVideo(event, item) {
      if (this.uploadVideoImagekit || this.uploadVideoServer) {
        const self = this
        const file = event.srcElement.files[0]
        this.$isLoading(true)
        this.sendVideoToServer(file, function (videoUrl) {
          if (videoUrl) {
            item.value = videoUrl
          }
          self.$isLoading(false)
        })
        document.getElementById('input-file-video').value = ''
      }
    },
    sendVideoToServer(file, callback) {
      const self = this
      if (this.uploadVideoImagekit) {
        if (!document.querySelector('script[src="https://unpkg.com/imagekit-javascript/dist/imagekit.min.js"]')) {
          const imageKitScript = document.createElement('script')
          imageKitScript.src = 'https://unpkg.com/imagekit-javascript/dist/imagekit.min.js'
          document.getElementsByTagName('head')[0].appendChild(imageKitScript)
        }
        const imagekit = new ImageKit(this.uploadVideoImagekit)
        imagekit.upload(
          {
            file: file,
            fileName: file.name,
          },
          function (err, result) {
            callback(result && result.url ? result.url : null)
          },
        )
        return true
      }

      if (this.uploadVideoServer) {
        const reader = new FileReader()
        const workoutName = this.workoutData.find(function (item) {
          return item.id === 'name'
        })
        reader.readAsDataURL(file)
        reader.onload = function () {
          const format = file.name.indexOf('.mp4') > -1 ? '.mp4' : '.mov'
          StorageApi.sendVideo(
            {
              name:
                self.uploadVideoServer.name +
                (workoutName.value
                  ? workoutName.value
                      .toLowerCase()
                      .normalize('NFD')
                      .replace(/[\u0300-\u036f]/g, '')
                      .replace(/[ ]/g, '-')
                  : '') +
                format,
              type: 0,
              file: reader.result,
            },
            function (response) {
              if (response.success) {
                StorageApi.getVideo(response.data.id, function (getResponse) {
                  if (getResponse.success && getResponse.data && getResponse.data[0]) {
                    callback(getResponse.data[0].file)
                  } else {
                    self.$alert(getResponse.message, '', 'warning', Utils.getAlertOptions())
                    callback(null)
                  }
                })
              } else {
                self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
                callback(null)
              }
            },
          )
        }
        reader.onerror = function () {
          callback(null)
        }
        return true
      }
    },
    openVideo(video) {
      this.videoOpened = video
      this.dialogVideo = true
    },
    closeVideo() {
      this.videoOpened = null
      this.dialogVideo = false
    },
  },
}
</script>
